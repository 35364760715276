import { initSentry as initSentryVendor } from 'actonic-controllers/dist/sentry/react';
import { sentryDsn } from 'constants/constants';
import { publicBuildPath } from 'constants/publicBuildPath';
import { Extras } from '@sentry/types';

export { logError, logErrorMessage } from 'actonic-controllers/dist/sentry/react';

let isSentryInited = false;

interface InitSentryOptions {
  initialExtras?: Extras;
  withGlobalHandlers?: boolean;
}

export const initSentry = ({
  initialExtras,
  withGlobalHandlers = IS_CLOUD,
}: InitSentryOptions = {}) => {
  if (isSentryInited) {
    console.warn('sentry already inited');
    return;
  }

  isSentryInited = true;

  if (RUNTIME_ENV !== 'development') {
    initSentryVendor({
      initialExtras,
      dsn: sentryDsn,
      release: RELEASE_CODE,
      environment: RUNTIME_ENV,
      withGlobalHandlers, // if app is running inside iframe or on the backend you can enable global handlers
      rewriteRootPath: {
        from: publicBuildPath,
        to: '', // to make path the same that Sentry set for the uploaded sourcemaps
      },
    });
  }
};

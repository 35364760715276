export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_SECONDS = 60;
export const HOURS_IN_SECONDS = 3600;
export const HOURS_IN_MINUTES = 60;
export const DAY_IN_HOURS = 8;
export const WEEK_IN_DAYS = 5;
export const FULL_DAY_IN_HOURS = 24;

export const FULL_DAY_IN_MILLISECONDS = FULL_DAY_IN_HOURS * HOURS_IN_SECONDS * SECOND_IN_MILLISECONDS;

export const addonKey = IS_CLOUD ? 'proficient.developer.plugins.jira.worklog-pivot' : window.atlassianAddonKey;
export const appUrlSuffix = IS_CLOUD ? `plugins/servlet/ac/${addonKey}/app` : 'secure/actonic-worklog-overview.jspa';

export const WEB_LINK_REGEX = new RegExp(
  'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
);

export const DEBOUNCE_SELECT_TIMEOUT = 500;

export const addonPathStart = `/plugins/servlet/ac/${window.atlassianAddonKey}`;

export const appPublicName = 'Report Builder';
export const reactAppRootId = 'rb-root';
export const appContentRootId = 'app-content-root';
// todo use it as className in each popup content container, so resizeIframe will get actual height of content
export const popupContentClassName = 'actonic-popup-content';

const sentryDsns = {
  production: 'https://8c90369f1b2449138ea0aeed841cc023@sentry.actonic.io/8',
  stage: 'https://76c662065a0243d3ba3e668aad7ac1f2@sentry.actonic.io/11',
  test: 'https://f41fd6ac2630455fbcfe9fec155113b5@sentry.actonic.io/12',
  // only for tests (need to comment check for RUNTIME_ENV in initSentry)
  development: 'https://f41fd6ac2630455fbcfe9fec155113b5@sentry.actonic.io/12',
};

const posthogProjectKeys = {
  production: 'phc_z8b6WITmDZcq12umKJppAc93TxTUdmtYW0YZjezByCi',
  stage: 'phc_JS4IRH20x54XLePtnGQX2o2vKYVAkTpoUBbDY5adv5t',
  test: 'phc_JS4IRH20x54XLePtnGQX2o2vKYVAkTpoUBbDY5adv5t',
  development: '',
};

export const posthogApiHost = 'https://eu.i.posthog.com';

export const sentryDsn = sentryDsns[RUNTIME_ENV];
export const posthogProjectKey = posthogProjectKeys[RUNTIME_ENV];

export const galleryViewTypes = {
  grid: 'grid',
  groups: 'groups',
};

export const REPORTS_ON_PAGE = 15;

export const searchIssuesTag = 'searchIssuesTag';

export const DashboardTabs = {
  myReports: 'my',
  shared: 'shared',
  createReport: 'createReport',
};

export const REPORTS_QUERY_KEY = {
  tab: 'tab',
  page: 'page',
};

export enum PaginatedRequestIds {
  issues = 'issues',
  worklogs = 'worklogs',
  changelogs = 'changelogs',
}

export enum InformUserCloseableMessageNames {
  srScriptWarningHidden = 'srScriptWarningHidden',
}

export enum LicenseStatus {
  trialExpired = 'trialExpired',
  commercialExpired = 'commercialExpired',
  serverExpired = 'serverExpired',
  none = 'none',
  notification = 'notification',
  active = 'active',
}

export enum Locale {
  de_DE = 'de_DE',
  en = 'en',
}

// eslint-disable-next-line import/no-self-import
import moment from 'moment';
// We need only import, after this parser will be instead of root.moment
import 'moment-jdateformatparser';
import i18n from 'utils/i18n';

i18n.on('languageChanged', () => {
  moment.locale(i18n.t('lang'));
});

declare module 'moment' {
  interface Moment {
    formatWithJDF(dateFormat: string): string;
  }
}

moment.locale(i18n.t('lang'));

export { moment };

let cached = '';

export const getContextPath = () => {
  if (IS_CLOUD) {
    throw new Error('getContextPath should be called only in DC-related code');
  }

  if (window.isUnitTestEnv) {
    return '';
  }

  if (!cached) {
    if (window.isSrIframeApp) {
      return window.AJSContextPath ?? '';
    }

    const metaEl = document.querySelector('[name="ajs-context-path"]');
    if (!metaEl) {
      throw new Error('Cannot find ajs-context-path on the page');
    }
    cached = metaEl.getAttribute('content') ?? '';
  }

  return cached;
};
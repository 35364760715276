import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    if (process.env.NODE_ENV === 'test') return callback('en');

    const { AJS, AP } = window;

    if (IS_CLOUD && AP) {
      return window.AP.user?.getLocale((locale) => {
        callback(locale.replace('_', '-'));
      });
    }

    if (!IS_CLOUD && AJS) {
      return callback(AJS.Meta.get('user-locale').replace('_', '-'));
    }

    return callback('en'); // todo may be we need to add SR bridge method to get locale inside sr iframe
  },
  init: () => { },
  cacheUserLanguage: () => { },
};

const initOptions = {
  fallbackLng: ['en', 'de', 'ru'],
  supportedLngs: ['en', 'de', 'ru'],
  load: 'all',
  react: {
    wait: true,
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false,
  },
};

if (process.env.JIRA_TYPE === 'cloud') {
  i18n.use(Backend);
  if (process.env.WEBPACK_DEV_SERVER) {
    initOptions.backend = {
        loadPath: `${__webpack_public_path__}locales/{{lng}}/{{ns}}.json`, // eslint-disable-line
    };
  }
} else {
  // eslint-disable-next-line global-require
  const enTranslation = require('public-cloud/locales/en/translation.json');
  // eslint-disable-next-line global-require, import/no-unresolved
  const deTranslation = require('public-cloud/locales/de/translation.json');
  // eslint-disable-next-line global-require
  const ruTranslation = require('public-cloud/locales/ru/translation.json');

  initOptions.resources = {
    en: {
      translation: enTranslation,
    },
    de: {
      translation: deTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
  };
}

export default i18n;

export const initI18n = () => {
  return i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init(initOptions);
};
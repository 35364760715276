import { JiraApParams } from 'types/JiraAP';

const requestCloud = (params: JiraApParams) => {
  // @ts-expect-error AP.request has wrong type for binaryAttachment
  return window.AP.request(params);
};

export const requestFunction = IS_CLOUD ? requestCloud : (...args: unknown[]) => {
  if (window.ActonicRB?.gadget) {
    return new Promise((resolve, reject) => {
      const arg1 = args[0];
      const arg2 = args[1];

      const params = {
        ...(arg2 ?? {}),
        ...(typeof arg1 === 'string' ? { url: arg1 } : (arg1 ?? {})),
        success: resolve,
        error: reject,
      };
      // @ts-expect-error
      window.AJS.$.ajax(params);
    });
  }

  // @ts-expect-error
  return window.AJS.$.ajax(...args);
};

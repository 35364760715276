export enum ReportCategory {
  scriptedReport = 'scriptedReport',
  scriptedFromGallery = 'scriptedFromGallery',
  universalReport = 'universalReport',
  urtReport = 'urtReport',
  calendar = 'calendar',
}

export enum ExternalEditorType {
  devServer = 'devServer',
  cloudEditor = 'cloudEditor',
}

export enum ReportPlacement {
  dashboard = 'dashboard',
}

export interface DashboardReportPlacementContext {
  dashboardId: string;
  dashboardItemId: string;
}